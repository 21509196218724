<template>
	<div>
		<div class="flex justify-between px-2 text-xl font-bold">
			#{{ order.id }}
			<div class="capitalize">
				{{ order.status }}
			</div>
		</div>

		<div class="border-b border-gray-300" />

		<div class="flex mt-4 mb-4">
			<div class="hidden md:flex">
				<UserAvatar
					v-if="shouldShowUserProfile"
					:user="user"
					:height="60"
					:width="60"
				/>
				<BusinessAvatar
					v-else
					:business="business"
					:image="business.thumbnail"
				/>
			</div>
			<div class="flex flex-col mx-2 text-left">
				<router-link
					:to="{
						name: 'businessIndex',
						params: businessLinkParams,
					}"
					class="text-2xl font-bold text-black"
				>
					{{ order.business.name }} - {{ order.business.city }}
				</router-link>
				<div class="flex mb-2">
					<a
						:href="`${order.business.directionsLink}`"
						class="flex items-center"
					>
						{{ fullBizAddress }}
						<img
							class="w-4 h-4 ml-2"
							src="@/assets/icons/navigation.svg"
							:alt="`Directions Link`"
						>
					</a>
				</div>
				<div class="mb-2 text-black">
					<a :href="`tel:${order.business.phone}`">
						{{ formattedPhone }}
					</a>
				</div>
			</div>
		</div>

		<div class="flex flex-wrap p-3 my-2 font-semibold text-center rounded-md shadow-md bg-gray-200">
			<div class="w-1/2 mb-2 md:w-1/4">
				<div class="text-xs font-normal uppercase text-black">
					Type
				</div>
				<div class="capitalize">
					{{ order.dispatch.type }}
				</div>
			</div>
			<div class="w-1/2 mb-2 md:w-1/4">
				<div class="text-xs font-normal uppercase text-black">
					Requested
				</div>
				<div v-if="order.timing.requested < 0">
					ASAP
				</div>
				<div v-else>
					<div>
						{{ requestedDate }}
					</div>
					<div>
						{{ requestedTime }}
					</div>
				</div>
			</div>
			<div class="w-1/2 mb-2 md:w-1/4">
				<div class="text-xs font-normal uppercase text-black">
					Ordered
				</div>
				<div>
					{{ datePlaced }}
				</div>
				<div>
					{{ timePlaced }}
				</div>
			</div>

			<div class="w-1/2 mb-2 md:w-1/4">
				<div class="text-xs font-normal uppercase text-black">
					Estimated
				</div>
				<div v-if="!order.timing.estimated.start">
					N/A
				</div>
				<div v-else>
					{{ estimatedTimeStart }} -
					{{ estimatedTimeEnd }}
				</div>
			</div>
		</div>
		<div class="py-4">
			<h2 class="px-2 mb-2 text-xl font-bold text-left">
				Order Details
			</h2>
			<template v-if="hasItems">
				<OrderDetailCard
					v-for="itemId in itemIds"
					:key="itemId"
					:item="itemData[itemId]"
					class="px-2 mb-2"
				/>
			</template>
			<template v-if="hasDeals">
				<ClaimedDealCard
					v-for="deal in order.deals"
					:key="`deal-${deal.id}`"
					:deal="deal"
				/>
			</template>
		</div>
		<div class="rounded-lg shadow-md bg-gray-200">
			<div class="flex items-center py-2 text-left">
				<div class="w-1/2 px-3 text-black">
					Subtotal
				</div>
				<div class="w-1/2 px-3 font-bold text-right">
					${{ order.subtotal }}
				</div>
			</div>
			<div class="flex items-center py-2 text-left bg-gray-200">
				<div class="w-1/2 px-3 text-black">
					Sales Tax
				</div>
				<div class="w-1/2 px-3 font-bold text-right">
					${{ order.tax }}
				</div>
			</div>
			<div
				v-if="order.dispatch.type === 'delivery'"
				class="flex items-center py-2 text-left bg-gray-200"
			>
				<div class="w-1/2 px-3 text-black">
					Delivery Fee
				</div>
				<div class="w-1/2 px-3 font-bold text-right">
					${{ order.dispatch.price }}
				</div>
			</div>
			<div v-if="hasAdjustedPrice">
				<div class="flex items-center py-2 text-left">
					<div class="w-1/2 px-3 text-black">
						Adjusted Price
					</div>
					<div class="w-1/2 px-3 font-bold text-right">
						${{ adjustedPriceDifference }}
					</div>
				</div>
				<div class="flex items-center py-2 text-left">
					<div class="w-1/2 px-3 text-black">
						Original Total
					</div>
					<div class="w-1/2 px-3 font-bold text-right">
						${{ total }}
					</div>
				</div>
			</div>
			<div class="flex items-center py-2 pb-2 text-lg text-left">
				<div class="w-1/2 px-3 font-semibold text-black">
					Total
				</div>
				<div class="w-1/2 px-3 font-bold text-right">
					${{ adjustedTotal }}
				</div>
			</div>
		</div>
		<SMSModal />
	</div>
</template>

<script async>
import { mapMutations } from 'vuex'

import BusinessAvatar from '@/components/business/BusinessAvatar.vue'
import OrderDetailCard from '@/components/order/OrderDetailCard.vue'
import SMSModal from '@/components/UI/modals/SMSModal.vue'
import UserAvatar from '@/components/user/UserAvatar.vue'
import { SMS_MODAL } from '@/constants/modal/names.js'
import formatPhone from '@/utils/formatPhone.js'
import { formatAddress } from '@/utils/formatText.js'
import { formatUnixDate, formatUnixTime } from '@/utils/formatUnixTime.js'

export default {
	components: {
		OrderDetailCard,
		UserAvatar,
		BusinessAvatar,
		SMSModal,
		ClaimedDealCard: () => import('@/components/deal/ClaimedDealCard.vue')
	},
	props: {
		order: {
			type: Object,
			default: function () {
				return {
					name: '',
					business: {},
					customer: {},
					timing: {},
					delivery: {},
					dispatch: {},
					items: {
						ids: [],
						data: {}
					},
					deals: [],
					defaultUserImage: require('@/assets/img-defaults/default-profile-icon.jpg'),
					defaultBusinessImage: require('@/assets/icons/shop2.svg')
				}
			}
		},
		profile: {
			type: String,
			default: ''
		}
	},
	data: function () {
		return {
			showIcon: false,
			SMS_MODAL
		}
	},
	computed: {
		shouldShowUserProfile() {
			return this.profile === 'business'
		},
		user() {
			return this.order.customer.profile
		},
		business() {
			return this.order.business
		},
		businessLinkParams() {
			// TODO this needs to come from the api.  The frontend can never build a business url because we never know exactly what it should be
			const { city, state, url } = this.order.business
			return {
				city,
				state,
				business: url,
				businessType: 'marijuana-dispensaries'
			}
		},
		total() {
			return (
				this.order.subtotal +
        this.order.tax +
        this.order.dispatch.price
			).toFixed(2)
		},
		fullBizAddress() {
			const { address, city, state, zip } = this.order.business
			return formatAddress({ address, city, state, zip })
		},
		fullCustomerAddress() {
			const { address, city, zip } = this.order.customer
			const { state } = this.order.business
			return formatAddress({ address, city, state, zip })
		},
		customerName() {
			if (
				this.order.customer.name == undefined ||
        this.order.customer.name == ''
			) {
				// first see if there is customer name data
				if (
					this.order.customer.profile.name == undefined ||
          this.order.customer.profile.name == ''
				) {
					// if not, check for profile data
					return 'User ' + this.order.customer.id // generic if no name
				} else {
					return this.order.customer.profile.name // use profile name
				}
			} else {
				return this.order.customer.name // use customer order name
			}
		},
		hasProfilePhoto() {
			return this.profile === 'business'
				? this.order.customer.profile &&
            this.order.customer.profile.image !== ''
				: this.order.business?.thumbnail !== ''
		},
		profileName() {
			return this.profile === 'business'
				? this.customerName
				: this.order.business?.name
		},
		profilePhoto() {
			let image = ''
			if (this.profile !== 'business') {
				image = this.order.business?.thumbnail
			} else {
				image = this.order.customer.profile.image
			}
			if (image == '') {
				return ''
			} else {
				if (this.profile !== 'business') {
					return '/listing_images/55x55/' + this.order.business?.thumbnail
				} else {
					return '/user_images/55x55/' + this.order.customer.profile?.image
				}
			}
		},
		profileInitials() {
			let name = ''
			if (this.profile !== 'business') {
				name = this.order.business?.name
			} else {
				name = this.customerName
			}
			let initials = name.match(/\b\w/g) || []
			initials = (
				(initials.shift() || '') + (initials.pop() || '')
			).toUpperCase()
			return initials
		},
		hasDeals() {
			return this.order.deals && this.order.deals.length > 0
		},
		itemIds () {
			return this.order.items && this.order.items.ids
		},
		itemData () {
			return this.order.items && this.order.items.data
		},
		hasItems () {
			return this.itemIds && this.itemIds.length > 0
		},
		formattedPhone() {
			return formatPhone(this.order?.business?.phone)
		},
		requestedTime() {
			return formatUnixTime(this.order?.timing?.requested, this.order?.timing?.timezone)
		},
		requestedDate() {
			return formatUnixDate(this.order?.timing?.requested)
		},
		timePlaced() {
			return formatUnixTime(this.order?.timing?.placed, this.order?.timing?.timezone)
		},
		datePlaced() {
			return formatUnixDate(this.order?.timing?.placed)
		},
		estimatedTimeStart() {
			return formatUnixTime(this.order?.timing?.estimated?.start, this.order?.timing?.timezone)
		},
		estimatedTimeEnd() {
			return formatUnixTime(this.order?.timing?.estimated?.end, this.order?.timing?.timezone)
		},
		hasAdjustedPrice() {
			return !!this.order?.adjustedPrice
		},
		adjustedPriceDifference() {
			return (parseFloat(this.order?.adjustedPrice || 0) - parseFloat(this.total)).toFixed(2)
		},
		adjustedTotal() {
			return parseFloat(this.order?.adjustedPrice || this.total).toFixed(2)
		}
	},
	watch: {
		order: {
			handler() {
				if (Boolean(this.order.verificationNeeded) === true) {
					this.showModal(SMS_MODAL)
				}
			},
			immediate: true
		}
	},
	mounted() {
		if (Boolean(this.order.verificationNeeded) === true) {
			this.showModal(SMS_MODAL)
		}
	},
	methods: {
		...mapMutations('modal', [ 'showModal' ]),
		handleImageLoadError() {
			this.showIcon = true
		}
	}
}
</script>
