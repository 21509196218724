<template>
	<div class="flex items-center">
		<div class="w-1/6">
			<img
				:src="image"
				alt="Product"
				class="w-full border rounded-lg max-w-90"
			>
		</div>
		<div class="w-1/2 text-left">
			<div class="m-2 line-clamp-2">
				{{ item.name }}
			</div>
		</div>
		<div class="w-1/6">
			x{{ item.quantity }}
		</div>
		<div class="w-1/6 font-bold text-right">
			${{ item.price }}
		</div>
	</div>
</template>

<script async>
import { MENU_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DEFAULT_LEAF_IMAGE } from '@/constants/search/defaultImage.js'
import buildImageUrl from '@/utils/builders/buildImageUrl.js'

export default {
	props: {
		item: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		itemHasImage() {
			return this.item.image !== '' && this.item.image !== null && this.item.image !== undefined
		},
		image() {
			return this.itemHasImage
				? buildImageUrl({
					file: this.item.image,
					width: 224,
					height: 224,
					imagePath: MENU_IMAGES
				})
				: DEFAULT_LEAF_IMAGE
		}
	}
}
</script>
